import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "card"
}
const _hoisted_2 = { class: "card-body p-0" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "card bg-primary card-xl-stretch mb-xl-8" }
const _hoisted_5 = { class: "card-body" }
const _hoisted_6 = { class: "text-white fw-bolder fs-2 mb-2 mt-5" }
const _hoisted_7 = { class: "fw-boldest text-white" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EntityLoading = _resolveComponent("EntityLoading")!
  const _component_vue_highcharts = _resolveComponent("vue-highcharts")!

  return (_ctx.loading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_EntityLoading)
        ])
      ]))
    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
        (_ctx.params.type === 'tile')
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.chart.value || 0), 1),
                  _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.params.title), 1)
                ])
              ])
            ]))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              (_ctx.chart.data)
                ? (_openBlock(), _createBlock(_component_vue_highcharts, {
                    key: 0,
                    type: "chart",
                    options: _ctx.chart.data
                  }, null, 8, ["options"]))
                : _createCommentVNode("", true)
            ], 64))
      ], 64))
}